<template>
<div>
    <employment-contract-type-list></employment-contract-type-list>
</div>
</template>

  
<script>
export default {}
</script>
